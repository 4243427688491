/** Attach this to input fields to replicate the foundation abide behaviour for invalid form inputs
 * Marks the parent with class 'error' and toggles ng-hide on all elements with class error within
 * the parent element of the input */
import { Directive, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { NgControl, NgForm, NgModel } from '@angular/forms';

@Directive({
	selector: '[ppHasError]',
})
export class ppHasErrorDirective {
	@Input('ppHasError') options?:any; // {ignorePristine: boolean, ignoreUntouched: boolean};
	@HostBinding('class.error') error = false;
	constructor(element: ElementRef, ngForm: NgForm, ngModel: NgModel) {
		ngForm.ngSubmit.subscribe(() => this.updateError(ngModel)); // Detect touched changes on NgModel, as it has no separate event emitter for that
		ngModel.statusChanges.subscribe(() => this.updateError(ngModel));
	}
	protected updateError(ngModel: NgModel) {
		this.error = !(ngModel.valid || ((ngModel.pristine && (!this.options || !this.options.ignorePristine)) && (ngModel.untouched && (!this.options || !this.options.ignoreUntouched))));
	}
}
@Directive({
	selector: '[ppIsError]',
})
export class ppIsErrorDirective implements OnInit {
	@Input('ppIsError') ngModel: NgModel;
	@Input('ignorePristine') ignorePristine?: boolean = false;
	@Input('ignoreUntouched') ignoreUntouched?: boolean = false;
	@HostBinding('hidden') hidden = true;
	constructor(private element: ElementRef, private ngForm: NgForm) {
	}
	ngOnInit(): void {
		if(!this.ngModel) {
			console.error(`You need to bind ppIsError to an existing ngModel in your form`);
			return;
		}
		let ngForm = this.ngModel.formDirective as NgForm;
		ngForm.ngSubmit.subscribe(() => this.updateHidden()); // Detect touched changes on NgModel, as it has no separate event emitter for that
		this.ngModel.statusChanges.subscribe(() => this.updateHidden());
	}
	protected updateHidden() {
		this.hidden = (this.ngModel.valid || this.ngModel.disabled || ((this.ngModel.pristine && (!this.ignorePristine)) && (this.ngModel.untouched && (!this.ignoreUntouched)))) || null; // Need to bind to null to remove the attribute
	}
}
// .directive('ppHasError', function() {
// 	return {
// 		restrict: 'A', //attribute only
// 		require: '?ngModel',
// 		link: function(scope, element, attrs, ngModel) {
// 			if( !ngModel )
// 				return;
// 			var formName = element.parents('form[name]').attr('name');
// 			if(!formName)
// 				throw 'Wrap your input in a form with a name attribute';
// 			if(!ngModel.$name || ngModel.$name === '')
// 				throw 'Make sure the input has a name attribute';
// 			var parent = element.parent();
// 			if(parent.is('label'))
// 				parent = parent.parent();
// 			var errEl = parent.find('.error');
// 			scope.$watchGroup([formName + '.' + ngModel.$name + '.$invalid', formName + '.' + ngModel.$name + '.$touched'], function(newValues, oldValues) {
// 				var isInvalid = newValues[0] === undefined ? oldValues[0] : newValues[0]; // Undefined means async validation is pending, use old value to avoid flicker
// 				var touched = newValues[1];
// 				var isError = isInvalid && touched;
// 				parent.toggleClass('error', isError);
// 				errEl.toggleClass('ng-hide', !isError);
// 			});
// 		}
// 	};
// })
